/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { SortingDirection } from '@cozero/models'

import { SetFiltersCallback } from './useOrganizationEmissionsFiltersState'
import { ColumnSorting } from './useSorting'

type Props = {
  setFilters: SetFiltersCallback
  setSorting: (sorting: ColumnSorting) => void
}

type ReturnType = {
  applyLocationFilter: () => void
}

/**
 * Applies location filter from query params
 * @param setFilters - Function to set filters
 * @param setSorting - Function to set sorting
 * @returns - Function to apply location filter
 */
export const useLocationQueryParamFilter = ({ setFilters, setSorting }: Props): ReturnType => {
  const [searchParams] = useSearchParams()

  const applyLocationFilter = React.useCallback(() => {
    const location = Number(searchParams.get('location'))
    if (!isNaN(location) && location) {
      setSorting(null) // Reset sorting (use default sorting)
      setFilters({ location: [location] })
    }
  }, [searchParams, setFilters, setSorting])

  return { applyLocationFilter }
}
