import { useEffect, useRef } from 'react'

/**
 * @attention Use this hook always at the end of the component.
 * It will run the callback only on the first render of the component.
 */
export const useFirstRenderEffect = (callback: () => void): void => {
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      callback()
    }
  }, [callback])
}
