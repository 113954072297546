/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useState } from 'react'

import {
  OrganizationCarbonFootprintFormValues,
  useOrganizationCarbonFootprintFormConfiguration,
} from './hooks/useOrganizationCarbonFootprintFormConfiguration'
import { useOrganizationCarboonFootprintFormSearchOptions } from './hooks/useOrganizationCarbonFootprintFormSearchOptions'

import '@/molecules/FilterDrawerForm/FilterDrawerForm'

import { LogEntriesTableColumnKey } from '@cozero/models'

import { FilterDrawerForm } from '@/molecules/FilterDrawerForm/FilterDrawerForm'

type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
  value: OrganizationCarbonFootprintFormValues
  onChange?: (value: OrganizationCarbonFootprintFormValues) => void
  onSubmit: (value: OrganizationCarbonFootprintFormValues) => void
  visibleColumns: LogEntriesTableColumnKey[]
}

export const OrganizationCarbonFootprintFiltersDrawer = ({
  open,
  onOpenChange,
  value,
  onChange,
  onSubmit,
  visibleColumns,
}: Props): JSX.Element => {
  const { inputs } = useOrganizationCarbonFootprintFormConfiguration()

  const [filters, setFilters] = useState<OrganizationCarbonFootprintFormValues>(value ?? {})

  const { options, onSearch, isLoading } = useOrganizationCarboonFootprintFormSearchOptions(filters)

  return (
    <FilterDrawerForm
      inputs={inputs}
      value={filters}
      onSubmit={(formValue) => {
        onSubmit(formValue)
        setFilters(formValue)
      }}
      onChange={(formValue) => {
        onChange?.(formValue)
        setFilters(formValue)
      }}
      onClose={() => onOpenChange(false)}
      open={open}
      options={options}
      onSearch={onSearch}
      isLoading={isLoading}
      visibleColumnsKeys={visibleColumns}
    />
  )
}
