import React from 'react'

import { OrganizationCarbonFootprintSearchDto } from '@cozero/dtos'

import { OrganizationCarbonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

export const SLICE_NAME = 'logEntriesOverview'

export type SelectedRows = { keys: React.Key[]; ids: number[] }

export const DEFAULT_STATE: {
  lastUpdateDate?: Date
  selectedRows: SelectedRows
  filters: OrganizationCarbonFootprintFormValues
  currentPageResults?: OrganizationCarbonFootprintSearchDto[]
} = {
  filters: {},
  selectedRows: {
    keys: [],
    ids: [],
  },
}
